<template>
    <el-dialog :title="title" class="dialogForm" width="45%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" ref="inputForm"
                 label-width="120px" :disabled="method==='view'" v-loading="loading">
            <el-form-item label="上传资源：" prop="resourceUrl">
                <HeadUpload :image="inputForm.resourceUrl" @getImgList="getImgList" v-if="method!=='view'"/>
                <el-image style="width: 100px; height: 100px" :src="inputForm.resourceUrl" :preview-src-list="[inputForm.resourceUrl]" v-if="method==='view'"></el-image>
            </el-form-item>
            <el-form-item label="资源名称：" prop="resourceName">
                <el-input v-model.trim="inputForm.resourceName" maxlength="20" placeholder="请输入资源名称（限20字）"></el-input>
            </el-form-item>
            <el-form-item label="产权人：" prop="propertyOwner">
                <el-input v-model.trim="inputForm.propertyOwner" maxlength="20" placeholder="请输入产权人（限20字）"></el-input>
            </el-form-item>
            <el-form-item label="来源：" prop="source">
                <el-input v-model.trim="inputForm.source" maxlength="20" placeholder="请输入来源（限20字）"></el-input>
            </el-form-item>
            <el-form-item label="产权标识：" prop="propertyIdentification">
                <el-input v-model.trim="inputForm.propertyIdentification" maxlength="50" placeholder="请输入产权标识（限50字）"></el-input>
            </el-form-item>
            <el-form-item label="原创签名：" prop="originalSignature">
                <el-input v-model.trim="inputForm.originalSignature" maxlength="20" placeholder="请输入原创签名（限20字）"></el-input>
            </el-form-item>
            <el-form-item label="原创作者：" prop="originalAuthor">
                <el-input v-model.trim="inputForm.originalAuthor" maxlength="20" placeholder="请输入原创作者（限20字）"></el-input>
            </el-form-item>
            <el-form-item label="成文时间：" prop="writtenTime">
                <el-date-picker class="w100i"
                                v-model="inputForm.writtenTime" value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                type="date" clearable placeholder="请选择">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="下载限制：" prop="downloadRestrictions">
                <el-select v-model="inputForm.downloadRestrictions" placeholder="请选择" clearable class="w100i">
                    <el-option label="仅本人下载" value="0"></el-option>
                    <el-option label="所有人下载" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="原创水印：" prop="originalWatermark">
                <el-switch v-model="inputForm.originalWatermark" active-value="0" inactive-value="1" @change="watermarkChange()"></el-switch>
            </el-form-item>
            <el-form-item label="是否分享：" prop="isShare">
                <el-switch v-model="inputForm.isShare" active-value="0" inactive-value="1" :disabled="method!=='edit'" @change="isShareChange()"></el-switch>
            </el-form-item>
            <el-form-item label="评级：" prop="rating">
                <el-select v-model="inputForm.rating" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('rating_type')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="评价：" prop="evaluate">
                <el-input v-model.trim="inputForm.evaluate" maxlength="50" placeholder="请输入评价（限50字）"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-if="method != 'view'" v-noMoreClick>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import HeadUpload from "@/components/headUpload"

    export default {
        components: {
            HeadUpload
        },
        data() {
            return {
                title: '',
                visible: false,
                method: '',
                loading: false,
                inputForm: {
                    id: '',
                    resourceUrl: '',
                    resourceName: '',
                    propertyOwner: '',
                    source: '',
                    propertyIdentification: '',
                    originalSignature: '',
                    originalAuthor: '',
                    writtenTime: '',
                    downloadRestrictions: '',
                    originalWatermark: '',
                    isShare: '',
                    rating: '',
                    evaluate: '',
                },
                dataRule: {
                    resourceUrl: [
                        {required: true, message: '上传资源不能为空', trigger: 'change'}
                    ],
                    resourceName: [
                        {required: true, message: '资源名称不能为空', trigger: 'blur'}
                    ],
                    propertyOwner: [
                        {required: true, message: '产权人不能为空', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            init(row, method) {
                this.loading = false
                if (row) {
                    this.inputForm.id = row.id
                } else {
                    this.inputForm.id = ''
                }
                this.method = method
                if (method === 'add') {
                    this.title = '新增知识产权信息'
                } else if (method === 'edit') {
                    this.title = '修改知识产权信息'
                } else if (method === 'view') {
                    this.title = '查看知识产权信息'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method !== 'add') {
                        this.getInfo()
                    }
                })
            },
            getInfo() {
                this.$axios(this.api.digital.dyygintellectualpropertyGetById + '/' + this.inputForm.id).then(data => {
                    if (data.status) {
                        this.recover(this.inputForm, data.data);
                    }
                })
            },
            // 获取图片
            getImgList(data) {
                this.inputForm.resourceUrl = data
                if (data) {
                    this.$refs['inputForm'].clearValidate(['resourceUrl']);
                }
            },
            // 是否分享
            isShareChange() {
                if(this.inputForm.isShare == 0) {
                    this.$message.info('请联系管理员！')
                    this.inputForm.isShare = 1
                }
            },
            watermarkChange() {
                if(this.inputForm.originalWatermark == 0 && !this.inputForm.originalSignature) {
                    this.$message.info('请先设置原创签名！')
                    this.inputForm.originalWatermark = 1
                }
            },
            // 提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        if(this.inputForm.originalWatermark == 0 && !this.inputForm.originalSignature) {
                            this.$message.info('请先设置原创签名！')
                            return
                        }
                        this.loading = true
                        let api = this.api.digital.dyygintellectualpropertySave,
                            methodType = 'post'
                        if (this.method == 'edit') {
                            api = this.api.digital.dyygintellectualpropertyUpdateById
                            methodType = 'put'
                        }
                        if(this.method == 'add') {
                            delete this.inputForm.id
                        }
                        this.$axios(api, this.inputForm, methodType).then(data => {
                            if (data && data.status) {
                                this.$message.success('操作成功')
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error('操作失败')
                            }
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>
